<template>
  <div class="pcm-wrapper">
    <div class="header">
      <div class="logo"></div>
      <div class="contactOfficial" @click="toTg"></div>
    </div>
    <div class="main">
      <van-tabs v-model="activeIndex" animated swipeable :scrollspy="false">
        <van-tab title="国产" key="0">
          <div class="listBox">
            <div class="bg1" v-if="os.isPhone" @click="getApkInfo('ios')"></div>
            <div class="bg1" v-else @click="getApkInfo('android')"></div>
          </div>
        </van-tab>
        <van-tab title="动漫" key="1">
          <div class="listBox">
            <div class="bg2" v-if="os.isPhone" @click="getApkInfo('ios')"></div>
            <div class="bg2" v-else @click="getApkInfo('android')"></div>
          </div>
        </van-tab>
        <van-tab title="黑料" key="2">
          <div class="listBox">
            <div class="bg3" v-if="os.isPhone" @click="getApkInfo('ios')"></div>
            <div class="bg3" v-else @click="getApkInfo('android')"></div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div class="bottomBox">
      <div
        class="downloadBtn"
        @click="getApkInfo('ios')"
        v-if="os.isPhone"
      ></div>
      <div class="downloadBtn" v-else @click="getApkInfo('android')"></div>
    </div>
    <!-- 安卓提示框 -->
    <van-overlay :show="androidPromptShow" z-index="999" class="androidOverlay">
      <div>
        <div class="overlayContent" @click.stop>
          <div class="confirmBtnBox" @click="androidPromptShow = false"></div>
        </div>
      </div>
    </van-overlay>
    <!-- 苹果提示框 -->
    <van-overlay :show="iosPromptShow" z-index="999" class="iosOverlay">
      <div>
        <div class="overlayContent" @click.stop>
          <div class="appName">两年半</div>
          <div class="desc">全免费 更丰富 更高清</div>
          <div class="confirmBtnBox" @click="iosPromptShow = false"></div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  props: ["os"],
  data() {
    return {
      androidPromptShow: false,
      iosPromptShow: false,
      activeIndex: 0,
    };
  },
  methods: {
    // 剪贴板
    doCopy() {
      let url = window.location.href;
      this.$copyText(url).then(
        () => {
          console.log("复制成功");
        },
        () => {
          console.log("复制失败");
        }
      );
    },
    async showInstallTutorial(type) {
      if (type == "ios") {
        this.iosPromptShow = true;
      } else {
        this.androidPromptShow = true;
      }
    },
    async getApkInfo(type) {
      await this.showInstallTutorial(type);
      setTimeout(() => {
        this.$emit("getApkInfo", type);
      }, 500);
    },
    toTg() {
      window.open("https://t.me/yase666668");
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}
.pcm-wrapper {
  height: 100%;
  width: 540px;
  background-color: white;
  margin: 0 auto;
  .header {
    height: 98px;
    background: linear-gradient(to bottom, #2f5fea, #529df7);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    .logo {
      height: 70px;
      width: 323.75px;
      background: url("./../../../assets/images/mobile1/logo.png") no-repeat;
      background-size: 100% 100%;
    }
    .contactOfficial {
      height: 45px;
      width: 135px;
      background: url("./../../../assets/images/mobile1/contactOfficial.png")
        no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  .main {
    padding: 10px 10px 0;
    height: 100%;
    ::v-deep .van-tabs {
      height: 100%;
      .van-tabs__wrap {
        height: 45px;
        border: 1px solid #e0e0e0;
        border-radius: 13px;
        .van-tabs__nav {
          display: flex;
          align-items: center;
          padding: 0 4px;
          .van-tab {
            width: 112px;
            height: 35px;
            border-radius: 12px;
            color: #757575;
            font-size: 14px;
            font-weight: 600;
          }
          .van-tab--active {
            color: white;
            background: linear-gradient(to right, #9cccfa, #c5e2fc);
          }
          .van-tabs__line {
            display: none;
          }
        }
      }
      .van-tabs__content {
        height: 100%;
        .van-tabs__track {
          height: 100%;
          .van-tab__pane-wrapper {
            height: 100%;
            .van-tab__pane {
              height: 100%;
            }
          }
        }
      }
    }
    .listBox {
      height: calc(100% - 241px);
      overflow-y: auto;
      padding-top: 10px;
      .bg1 {
        height: 859.7px;
        background: url("./../../../assets/images/mobile1/bg1.png") no-repeat;
        background-size: 100% 100%;
      }
      .bg2 {
        height: 859.7px;
        background: url("./../../../assets/images/mobile1/bg2.png") no-repeat;
        background-size: 100% 100%;
      }
      .bg3 {
        height: 859.7px;
        background: url("./../../../assets/images/mobile1/bg3.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .bottomBox {
    height: 98px;
    width: 540px;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    background: url("./../../../assets/images/mobile1/bottomBox.png") no-repeat;
    background-size: 100% 100%;
    .downloadBtn {
      height: 44px;
      width: 135px;
      border-radius: 5px;
      position: absolute;
      right: 24px;
      top: 30px;
      cursor: pointer;
    }
  }
  .androidOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    .overlayContent {
      width: 297px;
      height: 441px;
      background: url("./../../../assets/images/mobile1/android_tip.png")
        no-repeat;
      background-size: 100% 100%;
      border-radius: 12px;
      padding-top: 380px;
      .confirmBtnBox {
        height: 36px;
        width: 140px;
        border-radius: 40px;
        margin: 0 auto;
      }
    }
  }
  .iosOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    .overlayContent {
      width: 297px;
      height: 441px;
      background: url("./../../../assets/images/mobile1/iosTip.png") no-repeat;
      background-size: 100% 100%;
      border-radius: 12px;
      padding-top: 380px;
      position: relative;
      .appName {
        font-size: 10px;
        font-weight: 500;
        color: #242423;
        position: absolute;
        left: 79px;
        bottom: 102px;
        z-index: 1;
        background-color: #ffffff;
        width: 100px;
      }
      .desc {
        font-size: 7px;
        font-weight: 500;
        color: #8f908f;
        position: absolute;
        left: 79px;
        bottom: 92px;
        z-index: 1;
        background-color: #ffffff;
        width: 100px;
      }
      .confirmBtnBox {
        height: 36px;
        width: 140px;
        border-radius: 40px;
        margin: 0 auto;
      }
    }
  }
}
</style>
